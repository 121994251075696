import { RadioGroup } from "@headlessui/react";
import React from "react";

export default function FormField(props: {
  heading: any;
  description: any;
  placeholder: any;
  keyToSet: any;
  disabled: any;
  dropdown: any;
  dropdownOptions: string[];
}) {
  const [value, setValue] = React.useState(
    localStorage.getItem(props.keyToSet) || ""
  );
  React.useEffect(() => {
    localStorage.setItem(props.keyToSet, value);
  }, [value, props.keyToSet]);
  return (
    <RadioGroup className="w-full mt-4">
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {props.heading}
      </RadioGroup.Label>
      <RadioGroup.Description className="mt-1 flex items-center text-sm text-gray-500 w-2/3">
        {props.description}
      </RadioGroup.Description>
      <div>
        <div className="mt-2">
          {props.disabled && !props.dropdown && (
            <input
              type="client_id"
              name="client_id"
              id="client_id"
              disabled
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={props.placeholder}
              value={value}
            />
          )}
          {!props.disabled &&
            !props.dropdown && (
              <input
                type="client_id"
                name="client_id"
                id="client_id"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder={props.placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            )}
          {!props.disabled && props.dropdown && (
            <select
              id="client"
              name="client"
              autoComplete="client-name"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              onChange={(e) => setValue(e.target.value)}
            >
              {
                // map over the client ids and create an option for each
                props.dropdownOptions.map((option) => {
                  return <option>{option}</option>;
                })
              }
            </select>
          )}
        </div>
      </div>
    </RadioGroup>
  );
}
