import { Review } from "../models/review";

export default function ReviewList(props: { reviews: Review[] }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
      {props.reviews.map((review: Review) => {
        return (
          <div key={review.id} className="hover:opacity-80 cursor-pointer relative">
            <img
              className="rounded-lg max-h-42 w-full object-cover"
              src={review.thumbnail_url}
              alt={review.product_name}
            />
            <img
              src={review.product_img_url}
              alt="Product"
              className="absolute bottom-0 right-0 h-12 w-12 rounded-lg border-2 border-white"
            />
            <p className="mt-2 font-semibold capitalize">{review.product_name}</p>
            <p>{review.product_price}</p>
          </div>
        );
      })}
    </div>
  );
}
