import ProductList from "../compontents/productList";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import NewProductModal from "../compontents/newProductModal";
import { getItem } from "../services/api";
// products is coming soon so we have created a coming soon page for it

export default function Products() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    // get products
    getItem("products")
      .then((response) => response.json())
      .then((products) => {
        setProducts(products.data_object);
      });
  }, []);
  return (
    <div className="mt-8 w-full">
      <NewProductModal
        open={open}
        setOpen={setOpen}
        cancelButtonRef={cancelButtonRef}
      />
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Products</div>
        <button
          className="text-sm h-12 w-12 rounded-md p-2 font-semibold bg-indigo-600 hover:bg-indigo-500"
          onClick={() => setOpen(true)}
        >
          <PlusIcon className="text-white" />
        </button>
      </div>
      <div className="mt-4 w-full">
        <ProductList products={products}></ProductList>
        {/* <ComingSoon/> */}
      </div>
    </div>
  );
}
