import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getVideoData, updateItem } from "../services/api";

export default function NewVideoModal(props: {
  open: boolean;
  setOpen: any;
  cancelButtonRef: any;
}) {
  const [cardTitle, setCardTitle] = useState("Add New tiktok");
  const [cardDescription, setCardDescription] = useState(
    "Paste the link to your tiktok and we'll do the rest."
  );
  const [cardLogo, setCardLogo] = useState(
    <ShoppingBagIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
  );
  const [cardLogoColor, setCardLogoColor] = useState("bg-indigo-100");
  const [videoUrl, setVideoUrl] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showDoneButton, setShowDoneButton] = useState(false);

  
  const createVideo = () => {
    console.log(videoUrl);
    try {
      // create video
      setCardTitle("Loading...");
      setCardDescription("Your video details are being collected.");
      setCardLogo(
        <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
      );
      setCardLogoColor("bg-gray-100");
      setShowInput(false);
      setShowCancelButton(false);
      setShowAddButton(false);
      //   this is going to get the video details from the provided URL
      //   if these cannot be found, it will still respond and provide boilerplate data
      getVideoData(videoUrl)
        .then((response) => response.json())
        .then((videoInfo) => {
          // we will then add the collected (or boilerplate) data to the videos array
          updateItem("videos", videoInfo).then((response) => {
            if (response.status === 200) {
              setCardTitle("Success!");
              setCardDescription(
                "Your video has been added, you can edit the details on the video page."
              );
              setCardLogo(
                <CheckBadgeIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              );
              setCardLogoColor("bg-green-100");
              setShowDoneButton(true);

            } else {
              throw new Error(
                "There was an error adding your video, please try again."
              );
            }
          });
        });
    } catch (error) {
      setCardTitle("Error!");
      setCardDescription(
        "There was an error adding your video, please try again."
      );
      setCardLogo(
        <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      );
      setCardLogoColor("bg-red-100");
    }
  };

  const resetState = () => {
    setCardTitle("Add New tiktok");
    setCardDescription(
      "Paste the link to your tiktok and we'll do the rest."
    );
    setCardLogo(
      <ShoppingBagIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
    );
    setCardLogoColor("bg-indigo-100");
    setVideoUrl("");
    setShowInput(true);
    setShowCancelButton(true);
    setShowAddButton(true);
    setShowDoneButton(false);
    props.setOpen(false);
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={props.cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${cardLogoColor}`}
                  >
                    {cardLogo}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {cardTitle}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{cardDescription}</p>
                    </div>
                    {showInput && (
                      <div className="mt-2">
                        <input
                          className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="https://www.tiktok.com/video"
                          value={videoUrl}
                          onChange={(e) => setVideoUrl(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex justify-between">
                  {showCancelButton && (
                  <button
                    type="button"
                    className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => resetState()}
                    ref={props.cancelButtonRef}
                  >
                    Cancel
                  </button>
                  )}
                  {showAddButton && (
                    <button
                      type="button"
                      className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-500 sm:col-start-1 sm:mt-0"
                      onClick={() => createVideo()}
                    >
                      Add
                    </button>
                  )}
                  {showDoneButton && (
                    <button
                      type="button"
                      className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-500 sm:col-start-1 sm:mt-0"
                      onClick={() => resetState()}
                    >
                      Done
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
