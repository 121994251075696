import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import NewVideoModal from "../compontents/newVideoModal";
import { getItem } from "../services/api";
import VideoList from "../compontents/videoList";
// videos is coming soon so we have created a coming soon page for it

export default function Videos() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    // get videos
    getItem("videos")
      .then((response) => response.json())
      .then((videos) => {
        setVideos(videos.data_object);
      });
  }, [open]);
  return (
    <div className="mt-8 w-full">
      <NewVideoModal
        open={open}
        setOpen={setOpen}
        cancelButtonRef={cancelButtonRef}
      />
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Videos</div>
        <button
          className="text-sm h-12 w-12 rounded-md p-2 font-semibold bg-indigo-600 hover:bg-indigo-500"
          onClick={() => setOpen(true)}
        >
          <PlusIcon className="text-white" />
        </button>
      </div>
      <div className="mt-4 w-full">
        <VideoList videos={videos}></VideoList>
      </div>
    </div>
  );
}
