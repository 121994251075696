import shortFormShoppingLogo from "../shortformShoppingLogo.svg";
import { UserCircleIcon } from "@heroicons/react/24/outline";
export default function TopNav() {
  return (
    <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 items-center gap-x-6">
          <img
            className="max-h-12 my-4"
            src={shortFormShoppingLogo}
            alt="logo"
          />
        </div>
        <div className="flex items-center gap-x-4 group relative cursor-pointer" onClick={() => {localStorage.clear(); window.location.reload()}}>
          <UserCircleIcon className="h-8 w-8 text-gray-900/50" />
          <span
            className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2-translate-x-1/2 translate-y-full opacity-0 m-4 mt-2 mx-auto w-full"
          >
            Log out
          </span>
        </div>
      </div>
    </header>
  );
}
