import { useRef, useState } from "react";
import EditProductModal from "./editProductModal";
import { Product } from "../models/product";

export default function ProductList(props: { products: Product[] }) {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [productIdx, setProductIdx] = useState(0);
  const openModal = (idx: number) => {
    setOpen(true);
    setProductIdx(idx);
  };

  return (
    <>
      <EditProductModal
        open={open}
        setOpen={setOpen}
        cancelButtonRef={cancelButtonRef}
        products={props.products}
        productIdx={productIdx}
      />
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
        {props.products.map((product: any) => {
          return (
            <div
              onClick={() => {
                openModal(props.products.indexOf(product));
              }}
              key={product.product_url}
              className="hover:opacity-80 cursor-pointer"
            >
              <img
                className="rounded-lg max-h-42"
                src={product.product_img_url}
                alt={product.product_name}
              />
              <p className="mt-2 font-semibold capitalize">
                {product.product_name}
              </p>
              <p className="">{product.product_price}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
