import { Fragment, MutableRefObject, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { createParser, pollParser, updateItem } from "../services/api";

export default function NewProductModal(props: {
  open: boolean | undefined;
  cancelButtonRef: MutableRefObject<HTMLElement | null> | undefined;
  setOpen: (value: boolean) => void;
}) {
  const [cardTitle, setCardTitle] = useState("Add New Product");
  const [cardDescription, setCardDescription] = useState(
    "Paste the link to your product and we'll do the rest."
  );
  const [cardLogo, setCardLogo] = useState(
    <ShoppingBagIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
  );
  const [cardLogoColor, setCardLogoColor] = useState("bg-indigo-100");
  const [productUrl, setProductUrl] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // New state to track when processing starts
  const [isComplete, setIsComplete] = useState(false); // New state to track when processing is done
  const [loadingProgress, setLoadingProgress] = useState(0); // New state for loading progress

  const createProduct = () => {
    setCardTitle("Loading...");
    setCardDescription("Your product page is being loaded.");
    setCardLogo(<MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />);
    setCardLogoColor("bg-gray-100");
    setIsProcessing(true); // Processing starts, hide add button and input

    createParser(productUrl)
      .then((response) => response.json())
      .then((response) => {
        const startTime = Date.now();
        const pollInterval = 3000; // Poll every 3 seconds
        const timeout = 45000; // Stop after 45 seconds

        const poll = () => {
          pollParser(response.id, response.timestamp)
            .then((response) => response.json())
            .then((productInfo) => {
              if (productInfo.processed_data) {
                updateItem("products", productInfo.processed_data).then(
                  (response) => {
                    if (response.status === 200) {
                      successHandler();
                    } else {
                      errorHandler();
                    }
                  }
                );
              } else if (Date.now() - startTime > timeout) {
                errorHandler("Timeout reached without processed data.");
              } else {
                setTimeout(poll, pollInterval);
                setLoadingProgress((prevProgress) =>
                  Math.min(prevProgress + 100 / (timeout / pollInterval), 100)
                );
              }
            })
            .catch((error) => {
              console.error("Polling error:", error);
              errorHandler(error.message);
            });
        };

        poll();
      });
  };

  const successHandler = () => {
    setCardTitle("Success!");
    setCardDescription(
      "Your product has been added, you can edit the details on the product page."
    );
    setCardLogo(
      <CheckBadgeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
    );
    setCardLogoColor("bg-green-100");
    setIsProcessing(false); // Processing done
    setIsComplete(true); // Processing complete
    setLoadingProgress(0); // Reset loading progress
  };

  const errorHandler = (
    message = "There was an error adding your product, please try again."
  ) => {
    setCardTitle("Failed");
    setCardDescription(message);
    setCardLogo(
      <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
    );
    setCardLogoColor("bg-red-100");
    setIsProcessing(false); // Processing done
    setIsComplete(true); // Processing complete
    setLoadingProgress(0); // Reset loading progress
  };

  const closeAndRefresh = () => {
    props.setOpen(false);
    window.location.reload();
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={props.cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${cardLogoColor}`}
                  >
                    {cardLogo}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {cardTitle}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{cardDescription}</p>
                    </div>
                    {!isProcessing && !isComplete && (
                      <div className="mt-2">
                        <input
                          type="text"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="https://www.example.com/product"
                          value={productUrl}
                          onChange={(e) => setProductUrl(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  {isProcessing && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${loadingProgress}%` }}
                      ></div>
                    </div>
                  )}
                  {!isProcessing && !isComplete && (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={createProduct}
                    >
                      Add Product
                    </button>
                  )}
                  {!isProcessing && isComplete && (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => closeAndRefresh()}
                    >
                      Close
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
