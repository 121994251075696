import ColorPicker from "../compontents/colorPicker";
// import ToggleSelector from "../compontents/toggleSelector";
const colors = [
  { name: "Red", color: "ef4444" },
  { name: "Pink", color: "ec4899" },
  { name: "Purple", color: "a855f7" },
  { name: "Blue", color: "3b82f6" },
  { name: "Green", color: "22c55e" },
  { name: "Yellow", color: "eab308" },
  { name: "Black", color: "000000" },
  { name: "White", color: "ffffff" },
  { name: "Orange", color: "f97316" },
  { name: "Teal", color: "14b8a6" },
  { name: "Cyan", color: "06b6d4" },
  { name: "Sky", color: "0ea5e9" },
  { name: "Violet", color: "8b5cf6" },
  { name: "Fuchsia", color: "d946ef" },
  { name: "Rose", color: "f43f5e" },
  { name: "Lime", color: "84cc16" },
  { name: "Emerald", color: "10b981" },
  { name: "Amber", color: "f59e0b" },
  { name: "Gray", color: "6b7280" },
  { name: "Cool Gray", color: "4b5563" },
  { name: "Blue Gray", color: "1e293b" },
  { name: "Indigo", color: "6366f1" },
  { name: "Light Blue", color: "38bdf8" },
  { name: "Coral", color: "f87171" }
];


export default function Colors() {
  return (
    <div className="mt-4 w-full">
      <div className="text-lg font-semibold">Color Settings</div>
      <ColorPicker keyToSet={"primary-color"} colors={colors} heading={"Primary Color"} description={"Select the main color of the widget."}/>
      <ColorPicker keyToSet={"accent-color"} colors={colors} heading={"Accent Color"} description={"Select the accent color of the widget."}/>
      {/* <ToggleSelector keyToSet={"dark-mode"} heading={"Dark Mode"} description={"Enable or disable dark mode to accommodate a user's browser preferences."}/> */}
    </div>
  );
}
