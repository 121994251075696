import {  useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import React from 'react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
function findIndexById(id: any, listLayout: any[]) {
  return listLayout.findIndex(item => item.id === id);
}
export default function CardPicker(props:{cards: any[],heading: any, description:any, keyToSet: any}) {
  const [selectedCard, setSelectedCard] = useState(props.cards[findIndexById(localStorage.getItem(props.keyToSet),props.cards)] || props.cards[0])
  const [cardValue,setCardValue] = useState(localStorage.getItem(props.keyToSet) || props.cards[0].id)

  React.useEffect(() => {
    localStorage.setItem(props.keyToSet, cardValue)
    if (props.keyToSet === 'initial-layout'){
      if (cardValue==='feed-view'){
        localStorage.setItem('iframe-height', "600px")
      }
      else{
        localStorage.setItem('iframe-height', "150px")
      }
    }
  }, [cardValue, props.keyToSet])
  return (
    <RadioGroup className="mt-4" value={selectedCard} onChange={setSelectedCard}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {props.heading}
      </RadioGroup.Label>
      <RadioGroup.Description className="mt-1 flex items-center text-sm text-gray-500 w-2/3">
        {props.description}
        </RadioGroup.Description>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {props.cards.map((card: any) => (
          <RadioGroup.Option
            onClick={() => setCardValue(card.id)}
            key={card.id}
            value={card}
            className={({ active }) =>
              classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {card.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {card.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="">
                      {card.icon}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
