import { Video } from "../models/video";

export default function VideoList(props: { videos: any }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
      {props.videos.map((video: Video) => {
        return (
          <div
            key={video.video_url}
            className="hover:opacity-80 cursor-pointer"
          >
            <img
              className="rounded-lg max-h-42"
              src={video.thumbnail_url}
              alt={video.title}
            />
            <p className="truncate">{video.title}</p>
          </div>
        );
      })}
    </div>
  );
}
