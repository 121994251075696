export function StoriesIcon() {
  return (
    <div className="h-36 w-2/3 sm:w-full grid grid-cols-6 gap-1 mt-4 outline rounded-md outline-gray-200 p-2">
      <div className="h-8 bg-gray-200 rounded-lg" />
      <div className="h-8 bg-gray-200 rounded-lg" />
      <div className="h-8 bg-gray-200 rounded-lg" />
      <div className="h-8 bg-gray-200 rounded-lg" />
      <div className="h-8 bg-gray-200 rounded-lg" />
      <div className="h-8 bg-gray-200 rounded-lg" />
    </div>
  );
}

export function InteractiveButtonIcon() {
  return (
    <div className="grid place-content-end h-36 w-2/3 sm:w-full mt-4 outline rounded-md outline-gray-200 p-2">
      <div className="h-8 w-8 bg-gray-200 rounded-lg" />
    </div>
  );
}

export function FeedViewIcon() {
  return (
    <div className="h-36 w-2/3 sm:w-full grid grid-cols-3 gap-1 mt-4 outline rounded-md outline-gray-200 py-8 px-12">
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
      <div className="w-full bg-gray-200 rounded-lg" />
    </div>
  );
}

export function ProductInfoViewIcon() {
  return (
    <div className="h-36 w-2/3 sm:w-full grid place-items-center mt-4 outline rounded-md outline-gray-200 py-4 px-6">
      <div className="h-full w-full outline rounded-md outline-gray-200 grid grid-cols-2 p-2">
        <div className="w-2/3 bg-gray-200 rounded-lg" />
        <div className="">
          <div className="h-1/2 w-2/3 bg-gray-200 rounded-lg" />
          <div className="w-full bg-gray-200 h-1 rounded-lg my-1" />
          <div className="w-full bg-gray-200 h-1 rounded-lg my-1" />
          <div className="w-full bg-gray-200 h-1 rounded-lg my-1" />
          <div className="w-full bg-gray-200 h-1 rounded-lg my-1" />
        </div>
      </div>
    </div>
  );
}

export function TheaterViewIcon() {
  return (
    <div className="h-36 w-2/3 sm:w-full grid grid-cols-3 mt-4 outline rounded-md outline-gray-200 py-2">
      <div className="w-2/3 my-2 bg-gray-200 rounded-r-lg justify-self-start" />
      <div className="w-full bg-gray-200 rounded-lg grid content-end">
        <div className="bg-white rounded-sm h-4 w-4 m-1"></div>
        <div className="bg-white rounded-sm h-1 m-1"></div>
        <div className="bg-white rounded-sm h-1 m-1"></div>
      </div>
      <div className="w-2/3 my-2 bg-gray-200 rounded-l-lg justify-self-end" />
    </div>
  );
}

export function ScrollViewIcon() {
  return (
    <div className="h-36 w-2/3 sm:w-full grid grid-cols-3 mt-4 outline rounded-md outline-gray-200 py-2">
      <div className="" />
      <div className="w-full bg-gray-200 rounded-lg grid content-end">
        <div className="bg-white rounded-sm h-4 w-4 m-1"></div>
        <div className="bg-white rounded-sm h-1 m-1"></div>
        <div className="bg-white rounded-sm h-1 m-1"></div>
      </div>
      <div className="" />
    </div>
  );
}
