import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Product } from "../models/product";
import { replaceItem } from "../services/api";

export default function EditProductModal(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  cancelButtonRef: any;
  products: Product[];
  productIdx: number;
}) {
  const [productToEdit, setProductToEdit] = useState(
    props.products[props.productIdx]
  );
  const [cardTitle, setCardTitle] = useState("Edit Product");
  const [cardDescription, setCardDescription] = useState(
    "You can edit the product details here."
  );
  const [cardLogo, setCardLogo] = useState(
    <PencilIcon className="h-6 w-6 text-gray-800" aria-hidden="true" />
  );
  const [cardLogoColor] = useState("bg-gray-100");
  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const updateProducts = () => {
    setLoading(true);
    setCardTitle("Updating...");
    setCardDescription("Your product is being updated.");
    // setCardLogo(
    //   <PencilIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
    // );
    // Create a new array with the updated product
    const updatedProducts = props.products.map((product, index) => {
      if (index === props.productIdx) {
        return { ...product, ...productToEdit };
      }
      return product;
    });
    console.log(updatedProducts);
    // using the updateItem function to update the products
    replaceItem("products", updatedProducts).then((response) => {
      if (response.status === 200) {
        setIsComplete(true);
        setLoading(false);
        setCardTitle("Product Updated");
        setCardDescription("Your product has been updated.");
        setCardLogo(
          <CheckBadgeIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        );
      } else {
        console.error("Error updating products");
        setIsComplete(true);
        setLoading(false);
        setCardTitle("Error");
        setCardDescription("There was an error updating your product.");
        setCardLogo(
          <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        );
      }
    });
  };

  const closeAndRefresh = () => {
    props.setOpen(false);
    window.location.reload();
  };

  return (
    <>
      {props.open && (
        <Transition.Root show={props.open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={props.cancelButtonRef}
            onClose={props.setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div
                        className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${cardLogoColor}`}
                      >
                        {cardLogo}
                      </div>
                      <div className="mt-3 sm:mt-5 ">
                        <Dialog.Title
                          as="h3"
                          className="text-base text-center font-semibold leading-6 text-gray-900"
                        >
                          {cardTitle}
                        </Dialog.Title>
                        <div className="mt-2 text-center">
                          <p className="text-sm text-gray-500">
                            {cardDescription}
                          </p>
                        </div>
                        {!loading && !isComplete && (
                          <>
                            <div className="mt-2">
                              <img
                                className="rounded-lg max-h-42"
                                src={
                                  props.products[props.productIdx]
                                    .product_img_url
                                }
                                alt={
                                  props.products[props.productIdx].product_name
                                }
                              />
                            </div>

                            <div className="mt-2">
                              <label className="text-sm font-semibold text-gray-700 align-start">
                                Product Name
                              </label>
                              <input
                                type="text"
                                placeholder="Product Name"
                                className="w-full border-gray-300 rounded-md shadow-sm focus-visible:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                                defaultValue={
                                  props.products[props.productIdx].product_name
                                }
                                onChange={(e) => {
                                  setProductToEdit({
                                    ...productToEdit,
                                    product_name: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="text-sm font-semibold text-gray-700 align-start">
                                Product Price
                              </label>
                              <input
                                type="text"
                                placeholder="Product Price"
                                className="w-full border-gray-300 rounded-md shadow-sm focus-visible:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                                defaultValue={
                                  props.products[props.productIdx].product_price
                                }
                                onChange={(e) => {
                                  setProductToEdit({
                                    ...productToEdit,
                                    product_price: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="text-sm font-semibold text-gray-700 align-start">
                                Product Image URL
                              </label>
                              <input
                                type="text"
                                placeholder="Product Image URL"
                                className="w-full border-gray-300 rounded-md shadow-sm focus-visible:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                                defaultValue={
                                  props.products[props.productIdx]
                                    .product_img_url
                                }
                                onChange={(e) => {
                                  setProductToEdit({
                                    ...productToEdit,
                                    product_img_url: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mt-2">
                              <label className="text-sm font-semibold text-gray-700 align-start">
                                Product URL
                              </label>
                              <input
                                type="text"
                                placeholder="Product URL"
                                className="w-full border-gray-300 rounded-md shadow-sm focus-visible:ring-indigo-500 focus-visible:ring-2 focus-visible:ring-offset-2"
                                defaultValue={
                                  props.products[props.productIdx].product_url
                                }
                                onChange={(e) => {
                                  setProductToEdit({
                                    ...productToEdit,
                                    product_url: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {!loading && !isComplete && (
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => updateProducts()}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => props.setOpen(false)}
                          ref={props.cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {!loading && isComplete && (
                      <button
                        type="button"
                        className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        onClick={() => closeAndRefresh()}
                      >
                        Close
                      </button>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
