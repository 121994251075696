import { RadioGroup } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";


const keysToGet = [
  "primary-color",
  "accent-color",
  "dark-mode",
  "client-id",
  "meta-pixel-id",
  "initial-layout",
  "view-layout",
  "iframe-height",
];

export default function EmbeddingDemo(props: {
  heading: any;
  description: any;
}) {
  let url = `https://embed.shortformshopping.com?`;
  keysToGet.forEach((key) => {
    url += `${key}=${localStorage.getItem(key)}&`;
  });

  const openLink = () => {
    window.open(url, "_blank");
  };

  return (
    <RadioGroup className="w-full mt-4">
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {props.heading}
      </RadioGroup.Label>
      <RadioGroup.Description className="mt-1 flex items-center text-sm text-gray-500 w-2/3">
        {props.description}
      </RadioGroup.Description>
      <button
        className="mt-2 flex items-center gap-x-2 bg-gray-800 text-gray-100 px-4 py-2 rounded-md"
        onClick={() => openLink()}
      >
        <span>View Live</span>
        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
      </button>
    </RadioGroup>
  );
}
