import { Amplify } from "aws-amplify";

export function getItem(recordType: string) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const groups =
    Amplify.Auth.user.signInUserSession.accessToken.payload["cognito:groups"];
  const client_id = localStorage.getItem("client-id");
  // make sure client_id is in the groups
  if (!groups.includes(client_id)) {
    return Promise.reject("Client ID not in groups");
  }
  // const token = Amplify.Auth.user.signInUserSession.accessToken.jwtToken;
  const url = `${apiUrl}/${client_id}/${recordType}`;
  // return the fetch promise
  // no cors
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

export function updateItem(recordType: string, data: any) {
  // get groups and token from amplify
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const groups =
    Amplify.Auth.user.signInUserSession.accessToken.payload["cognito:groups"];
  const client_id = localStorage.getItem("client-id");
  // make sure client_id is in the groups
  if (!groups.includes(client_id)) {
    return Promise.reject("Client ID not in groups");
  }
  data = { client_id: client_id, object_type: recordType, data_object: [data] };
  const token = Amplify.Auth.user.signInUserSession.idToken.jwtToken;
  const url = `${apiUrl}/${client_id}/${recordType}`;
  // return the fetch promise
  return fetch(url, {
    method: "PATCH",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function replaceItem(recordType: string, data: any) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const groups =
    Amplify.Auth.user.signInUserSession.accessToken.payload["cognito:groups"];
  const client_id = localStorage.getItem("client-id");
  // make sure client_id is in the groups
  if (!groups.includes(client_id)) {
    return Promise.reject("Client ID not in groups");
  }
  data = { client_id: client_id, object_type: recordType, data_object: data };
  const token = Amplify.Auth.user.signInUserSession.idToken.jwtToken;
  const url = `${apiUrl}/${client_id}/${recordType}`;
  // return the fetch promise
  return fetch(url, {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function deleteItem(recordType: string, id: string) {
  // get groups and token from amplify
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const groups =
    Amplify.Auth.user.signInUserSession.accessToken.payload["cognito:groups"];
  const client_id = localStorage.getItem("client-id");
  // make sure client_id is in the groups
  if (!groups.includes(client_id)) {
    return Promise.reject("Client ID not in groups");
  }
  const token = Amplify.Auth.user.signInUserSession.accessToken.jwtToken;
  const url = `${apiUrl}/${client_id}/${recordType}`;
  // return the fetch promise
  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });
}

export function getVideoData(post_url: string) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const groups =
    Amplify.Auth.user.signInUserSession.accessToken.payload["cognito:groups"];
  const client_id = localStorage.getItem("client-id");
  // make sure client_id is in the groups
  if (!groups.includes(client_id)) {
    return Promise.reject("Client ID not in groups");
  }
  const url = `${apiUrl}/tiktok`;
  // return the fetch promise
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      post_url: post_url,
      client_id: client_id,
    }),
  });
}

export function createParser(target_url: string) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const url = `${apiUrl}/parser`;
  // return the fetch promise
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      target_url: target_url,
      keys_list: [
        {
          key_to_extract: "product_name",
          description_of_key: "the name of the product on the page",
        },
        {
          key_to_extract: "product_desc",
          description_of_key: "the product description on the page",
        },
        {
          key_to_extract: "product_price",
          description_of_key: "the product price on the page",
        },
        {
          key_to_extract: "product_img_url",
          description_of_key: "the url for the main product image on the page",
        },
      ],
    }),
  });
}

export function pollParser(id: string, timestamp: string) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const url = `${apiUrl}/parser/${id}/${timestamp}`;
  // return the fetch promise
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
