import TopNav from "./compontents/topNav";
import SecondaryNav from "./compontents/secNav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import General from "./routes/settingsGeneral";
import Layout from "./routes/settingsLayout";
import Colors from "./routes/settingsColors";
import Embed from "./routes/settingsEmbed";
import Products from "./routes/settingsProducts";
import shortformShoppingLogo from "./shortformShoppingLogo.svg"
import {
  View,
  useTheme,
  Image,
  Theme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import Videos from "./routes/settingsVideos";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Reviews from "./routes/settingsReviews";
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});
export default function App() {
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="ShortForm Logo"
            width="150px"
            src={shortformShoppingLogo}
          />
        </View>
      );
    },
  };
  const { tokens } = useTheme();
  const theme: Theme = {
    name: 'Auth Example Theme',
    tokens: {
      colors: {
        font: {
          interactive: {
            value: tokens.colors.black.value,
          },
        },
        brand: {
          primary: {
            '10': "#4d4dff",
            '80': "#5555ff",
            '90': "#6666ff",
            '100': "#8080ff",
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.black.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.black.value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.black.value,
              },
            },
          },
        },
      },
    },
  };

  const formFields = {
    resetPassword: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
    signIn: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
    signUp: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
      given_name: {
        placeholder: "Enter Your First Name Here",
        isRequired: true,
        label: "First Name:",
      },
      family_name: {
        placeholder: "Enter Your Last Name Here",
        isRequired: true,
        label: "Last Name:",
      },
    },
  };

  return (
    <>
    <ThemeProvider theme={theme}>
      <Authenticator
        signUpAttributes={["given_name", "family_name"]}
        formFields={formFields}
        components={components}
        className="mt-4"
      >
        <TopNav />
        <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 px-2 lg:px-8">
          <Router>
            <SecondaryNav />
            <div></div>
            <Routes>
              <Route path="/" Component={General} />
              <Route path="/general" Component={General} />
              <Route path="/layout" Component={Layout} />
              <Route path="/colors" Component={Colors} />
              <Route path="/embed" Component={Embed} />
              <Route path="/reviews" Component={Reviews} />
              <Route path="/products" Component={Products} />
              <Route path="/videos" Component={Videos} />
              <Route path="*" Component={General} />
            </Routes>
          </Router>
        </div>
      </Authenticator>
      </ThemeProvider>
    </>
  );
}
