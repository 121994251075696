import EmbeddingDemo from "../compontents/embeddingDemo";
import EmbeddingViewer from "../compontents/embeddingViewer";



export default function Embed() {
  return (
    <div className="mt-4 w-full">
      <div className="text-lg font-semibold">Embed Configuration Into Site</div>
      <EmbeddingViewer heading={"HTML Embedding"} description={"Paste this into your site directly your with your current settings configuration."}/>
      <EmbeddingDemo heading={"View Demo"} description={"Click here to view what your current embedding configuration looks like live."}/>
    </div>
  );
}
