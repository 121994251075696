import {
  CodeBracketIcon,
  RectangleGroupIcon,
  ShoppingBagIcon,
  SquaresPlusIcon,
  SwatchIcon,
  UserCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const secondaryNavigation = [
  { name: "General", path: "general", icon: UserCircleIcon, current: false },
  { name: "Videos", path: "videos", icon: VideoCameraIcon, current: false},
  { name: "Products", path: "products", icon: ShoppingBagIcon, current: false },
  { name: "Reviews", path: "reviews", icon: SquaresPlusIcon , current: false},
  { name: "Layout", path: "layout", icon: RectangleGroupIcon, current: false },
  { name: "Colors", path: "colors", icon: SwatchIcon, current: false },
  { name: "Embed", path: "embed", icon: CodeBracketIcon, current: false },  
];

export default function SecondaryNav() {
  const location = useLocation();
  const navigator = useNavigate();
  function currentPath(path: string) {
    return location.pathname.replaceAll("/", "") === path;
  }

  return (
    <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
      <nav className="flex-none px-4 sm:px-6 lg:px-0">
        <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <button
                onClick={() => navigator(item.path)}
                className={classNames(
                  currentPath(item.path)
                    ? "bg-gray-50 text-indigo-600"
                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                  "group flex w-full gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                )}
              >
                <item.icon
                  className={classNames(
                    currentPath(item.path)
                      ? "text-indigo-600"
                      : "text-gray-400 group-hover:text-indigo-600",
                    "h-6 w-6 shrink-0"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}
