import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import React from "react";

function findIndexByName(name: any, listLayout: any[]) {
  return listLayout.findIndex((item) => item.name === name);
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ColorPicker(props: { heading: any; description: any, colors:any, keyToSet: any }) {
  const [selectedColor, setSelectedColor] = useState(props.colors[findIndexByName(localStorage.getItem(props.keyToSet),props.colors)] || props.colors[0]);
  const [colorValue,setCardValue] = useState(localStorage.getItem(props.keyToSet) || props.colors[0].color)
  React.useEffect(() => {
    localStorage.setItem(props.keyToSet, colorValue)
  }, [colorValue, props.keyToSet])
  return (
    <RadioGroup
      value={selectedColor}
      onChange={setSelectedColor}
      className="w-full mt-4"
    >
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {props.heading}
      </RadioGroup.Label>
      <RadioGroup.Description className="mt-1 flex items-center text-sm text-gray-500 w-2/3">
        {props.description}
      </RadioGroup.Description>
      <div className="mt-4 p-2 flex flex-wrap items-center  overflow-x-scroll">
        {props.colors.map((color: any) => (
          <RadioGroup.Option
          onClick={() => setCardValue(color.color)}
            key={color.color}
            value={color.color}
            className={({ active, checked }) =>
              classNames(
                `ring-[#${color.color}] m-1`,
                active && checked ? "ring ring-offset-1" : "",
                !active && checked ? "ring-2" : "",
                "relative flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
              )
            }
          >
            <RadioGroup.Label as="span" className="sr-only">
              {color.name}
            </RadioGroup.Label>
            <span
              aria-hidden="true"
              className={classNames(
                `bg-[#${color.color}]`,
                "h-8 w-8 rounded-full border border-black border-opacity-10"
              )}
            />
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
