
import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { getItem } from "../services/api";
import NewReviewModal from "../compontents/newReviewModal";
import ReviewList from "../compontents/reviewList";
// reviews is coming soon so we have created a coming soon page for it

export default function Reviews() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    // get reviews
    getItem("reviews")
      .then((response) => response.json())
      .then((reviews) => {
        setReviews(reviews.data_object);
      });
  }, [open]);
  return (
    <div className="mt-8 w-full">
      <NewReviewModal
        open={open}
        setOpen={setOpen}
        cancelButtonRef={cancelButtonRef}
      />
      <div className="flex justify-between">
        <div className="text-lg font-semibold">Reviews</div>
        <button
          className="text-sm h-12 w-12 rounded-md p-2 font-semibold bg-indigo-600 hover:bg-indigo-500"
          onClick={() => setOpen(true)}
        >
          <PlusIcon className="text-white" />
        </button>
      </div>
      <div className="mt-4 w-full">
        <ReviewList reviews={reviews}></ReviewList>
      </div>
    </div>
  );
}
