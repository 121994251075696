import { useEffect } from "react";
import FormField from "../compontents/formField";
import { Auth } from "aws-amplify";
import React from "react";

export default function General() {
  const [multiGroupUser, setMultiGroupUser] = React.useState(false);
  const [clientIds, setClientIds] = React.useState([]);
  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser().then((user) => {
        let groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        console.log(groups);
        if (groups.length > 1) {
          setMultiGroupUser(true);
        }
        localStorage.setItem("client-ids", groups);
        setClientIds(groups);
        localStorage.setItem("client-id", groups[0]);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="mt-4 w-full">
      <div className="text-lg font-semibold">General Settings</div>
      {!multiGroupUser && (
        <FormField
          disabled={true}
          keyToSet={"client-id"}
          heading={"Client Id"}
          description={
            "Enter your client ID here. This will be used to identify your site's products."
          }
          placeholder={localStorage.getItem("client-id")}
          dropdown={false}
          dropdownOptions={[]}
        />
      )}
      {multiGroupUser && (
        <FormField
          disabled={false}
          keyToSet={"client-id"}
          heading={"Client Id"}
          description={
            "Enter your client ID here. This will be used to identify your site's products."
          }
          placeholder={localStorage.getItem("client-id")}
          dropdown={true}
          dropdownOptions={clientIds}
        />
      )}

      <FormField
        disabled={false}
        keyToSet={"meta-pixel-id"}
        heading={"Meta Pixel Id"}
        description={
          "Enter your Meta Pixel ID here. This will be used to identify your site's traffic."
        }
        placeholder={"1234567890"}
        dropdown={false}
        dropdownOptions={[]}
      />
    </div>
  );
}
