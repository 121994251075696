import CardPicker from "../compontents/cardPicker";
import {
  FeedViewIcon,
  InteractiveButtonIcon,
  ProductInfoViewIcon,
  ScrollViewIcon,
  StoriesIcon,
  TheaterViewIcon,
} from "../compontents/layoutIcons";

const listLayout = [
  {
    id: "story-view",
    title: "Story View",
    description: "Load each product video on the page, as a list of stories",
    icon: <StoriesIcon />,
  },
  {
    id: "feed-view",
    title: "Feed View",
    description: "Load each product video on the page, as a feed style list",
    icon: <FeedViewIcon />,
  },
  {
    id: "featured-view",
    title: "Featured View",
    description:
      "Load a single, autoplaying product video in the corner of the page",
    icon: <InteractiveButtonIcon />,
  },
];
const viewLayout = [
  {
    id: "product-info-view",
    title: "Product Info View",
    description:
      "Display the video next to the product image and description in popup window",
    icon: <ProductInfoViewIcon />,
  },
  {
    id: "horizontal-scroll-view",
    title: "Horizontal Scroll View",
    description:
      "Display the product video in the center of the screen with product details at the bottom",
    icon: <TheaterViewIcon />,
  },
  {
    id: "vertical-scroll-view",
    title: "Vertical Scroll View",
    description:
      "Display the product video in the center of the screen with product details at the bottom",
    icon: <ScrollViewIcon />,
  },
];

export default function Layout() {
  return (
    <div className="mt-4">
      <div className="text-lg font-semibold">Layout Settings</div>
      <CardPicker
        keyToSet={"initial-layout"}
        cards={listLayout}
        heading={"Initial Layout"}
        description={
          "Choose the layout that will initially appear when the page loads. This will display video thumbnails that traffic can interact with."
        }
      />
      <CardPicker
        keyToSet={"view-layout"}
        cards={viewLayout}
        heading={"Viewing Layout"}
        description={
          "Choose the layout that will appear after a user selects a video. This will be the view that users can watch content in."
        }
      />
    </div>
  );
}
