import { RadioGroup } from "@headlessui/react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import React from "react";
import { CodeBlock, nord } from "react-code-blocks";

const keysToGet = [
  "primary-color",
  "accent-color",
  "dark-mode",
  "client-id",
  "meta-pixel-id",
  "initial-layout",
  "view-layout",
  "iframe-height",
];

export default function EmbeddingViewer(props: {
  heading: any;
  description: any;
}) {
  const [copied, setCopied] = React.useState(false);
  let url = `https://embed.shortformshopping.com?`;
  keysToGet.forEach((key) => {
    url += `${key}=${localStorage.getItem(key)}&`;
  });

  const codeBlock = `<div id="iframe-parent" style="border: 0px; z-index: 99999999999; overflow: hidden; position: fixed width: 100%; height: ${localStorage.getItem('iframe-height')}; max-width: 100%; max-height: 100%;">
<iframe src="${url}" id="iframe-source" style="position:absolute border: 0px; float: none; inset: 0px; width: 100%; height: 100%; margin: 0px; padding: 0px; min-height: 0px;"></iframe></div>
<script>const iframeParent = document.getElementById("iframe-parent");window.addEventListener('message', function (e) {let message = e.data;if (!document.getElementById("iframe-parent")) {return;}document.getElementById("iframe-parent").style.top = message.top; document.getElementById("iframe-parent").style.height = message.height; document.getElementById("iframe-parent").style.width = message.width; document.getElementById("iframe-parent").style.position = message.position;}, false);</script>`;

  const cleanCodeBlock =`<iframe src="${url}" id="iframe-source" ></iframe>`

  const addCodeBlockToClipboard = () => {
    // add the code block object to users clipboard
    navigator.clipboard.writeText(codeBlock);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <RadioGroup className="w-full mt-4">
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        {props.heading}
      </RadioGroup.Label>
      <RadioGroup.Description className="mt-1 flex items-center text-sm text-gray-500 w-2/3">
        {props.description}
      </RadioGroup.Description>
      <div className="mt-4 h-full bg-gray-600 rounded-md overflow-x-scroll">
        <div className="w-full text-white h-12 px-2 flex items-center justify-between ">
          <div>{copied ? "Copied to clipboard!" : "Embedding Code"}</div>
          <button
            onClick={() => addCodeBlockToClipboard()}
            className="hover:bg-gray-500 rounded-md p-1"
          >
            <ClipboardDocumentIcon className="h-8" />
          </button>
        </div>
        <CodeBlock
          wrapLongLines
          text={cleanCodeBlock}
          language={"tsx"}
          showLineNumbers={false}
          theme={nord}
        />
      </div>
    </RadioGroup>
  );
}
