import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckBadgeIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { getItem, updateItem } from "../services/api";
import { Product } from "../models/product";
import { Video } from "../models/video";

export default function NewReviewModal(props: {
  open: boolean;
  setOpen: any;
  cancelButtonRef: any;
}) {
  const [cardTitle, setCardTitle] = useState("Add New Review");
  const [cardDescription, setCardDescription] = useState(
    "Select a product and match it to a tiktok video to form a review."
  );
  const [cardLogo, setCardLogo] = useState(
    <ShoppingBagIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
  );
  const [cardLogoColor, setCardLogoColor] = useState("bg-indigo-100");
  const [showProductList, setShowProductList] = useState(true);
  const [showVideoList, setShowVideoList] = useState(true);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showDoneButton, setShowDoneButton] = useState(false);
  const [products, setProducts] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedVideo, setSelectedVideo] = useState<Video>();
  useEffect(() => {
    // get products
    getItem("products")
      .then((response) => response.json())
      .then((products) => {
        setProducts(products.data_object);
      });
    // get videos
    getItem("videos")
      .then((response) => response.json())
      .then((videos) => {
        setVideos(videos.data_object);
      });
  }, []);

  const createReview = (product: Product, video: Video) => {
    const reviewInfo = {
      ...product,
      ...video,
    };
    // create video
    setCardTitle("Loading...");
    setCardDescription("Your video & product details are being collected.");
    setCardLogo(<MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />);
    setCardLogoColor("bg-gray-100");
    setShowProductList(false);
    setShowVideoList(false);
    setShowCancelButton(false);
    setShowAddButton(false);
    try {
      // we will then add the collected (or boilerplate) data to the videos array
      updateItem("reviews", reviewInfo).then((response) => {
        if (response.status === 200) {
          setCardTitle("Success!");
          setCardDescription("Your review has been created!");
          setCardLogo(
            <CheckBadgeIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          );
          setCardLogoColor("bg-green-100");
          setShowDoneButton(true);
        } else {
          throw new Error(
            "There was an error adding your video, please try again."
          );
        }
      });
    } catch (error) {
      setCardTitle("Error!");
      setCardDescription(
        "There was an error adding your review, please try again."
      );
      setCardLogo(
        <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      );
      setCardLogoColor("bg-red-100");
    }
  };

  const resetState = () => {
    setCardTitle("Add New Review");
    setCardDescription(
      "Select a product and match it to a tiktok video to create a review."
    );
    setCardLogo(
      <SquaresPlusIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
    );
    setCardLogoColor("bg-indigo-100");
    setShowProductList(true);
    setShowVideoList(true);
    setShowCancelButton(true);
    setShowAddButton(true);
    setShowDoneButton(false);
    props.setOpen(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={props.cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${cardLogoColor}`}
                  >
                    {cardLogo}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {cardTitle}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{cardDescription}</p>
                    </div>

                    {/* Products Section */}
                    {showProductList && (
                      <div className="mt-4 mb-4">
                        <div className="flex flex-nowrap overflow-x-auto">
                          {products.map((product: Product) => (
                            <div className="relative mr-4 flex-shrink-0">
                              <div className="transition duration-300 ease-in-out overflow-hidden rounded-lg shadow-indigo-500/50 border-2 border-indigo-500 w-full max-w-xs">
                                <img
                                  className="h-36 w-full object-cover cursor-pointer hover:scale-105 hover:opacity-90 rounded-lg"
                                  src={product.product_img_url}
                                  alt={product.product_name}
                                  onClick={() =>
                                    setSelectedProduct(product)
                                  }
                                />
                              </div>
                              {selectedProduct === product && (
                                <div className="absolute top-2 left-2 h-6 w-6 rounded-full bg-indigo-500 flex items-center justify-center">
                                  <svg
                                    className="h-4 w-4 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Videos Section */}
                    {showVideoList && (
                      <div className="mt-4 mb-4">
                        <div className="flex flex-nowrap overflow-x-auto">
                          {videos.map((video: Video) => (
                            <div className="relative mr-4 flex-shrink-0">
                              <div className="transition duration-300 ease-in-out overflow-hidden rounded-lg shadow-indigo-500/50 border-2 border-indigo-500 w-full max-w-xs">
                                <img
                                  className="h-36 w-full object-cover cursor-pointer hover:scale-105 hover:opacity-90 rounded-lg"
                                  src={video.thumbnail_url}
                                  alt={video.title}
                                  onClick={() =>
                                    setSelectedVideo(video)
                                  }
                                />
                              </div>
                              {selectedVideo === video && (
                                <div className="absolute top-2 left-2 h-6 w-6 rounded-full bg-indigo-500 flex items-center justify-center">
                                  <svg
                                    className="h-4 w-4 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex justify-between">
                  {showCancelButton && (
                    <button
                      type="button"
                      className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => resetState()}
                      ref={props.cancelButtonRef}
                    >
                      Cancel
                    </button>
                  )}
                  {showAddButton && selectedProduct !== undefined && selectedVideo !== undefined &&(
                    <button
                      type="button"
                      className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-500 sm:col-start-1 sm:mt-0"
                      onClick={() => createReview(selectedProduct, selectedVideo)}
                    >
                      Add
                    </button>
                  )}
                  {showDoneButton && (
                    <button
                      type="button"
                      className="mt-3 my-1 mx-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-500 sm:col-start-1 sm:mt-0"
                      onClick={() => resetState()}
                    >
                      Done
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
